@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-popup-content {
  margin: 0;
}

/* GLASS */
.glass {
  position: relative;
  width: 40vmin;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.glass svg {
  position: relative;
  z-index: 1;
  display: block;
  transform: translateZ(100vmin);
}

.glass .wrapper {
  position: absolute;
  left: 10.5%;
  right: 10.5%;
  top: 5%;
  bottom: -15%;
  transform: perspective(96vmin) rotateX(-30.8deg);
}

.glass .contents {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 0 25% 25% / 0 0 10% 10%;
  overflow: hidden;
}

/* BEER */
.glass .beer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background-size: 100% 100%;
  animation: fill 4s ease forwards;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1526092/beer.png");
  /* Had to use an image for the gradient because css linear gradients don't create a smooth enough ramp between stops for my liking. */
}

.glass .surface {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  transform: scale(0);
  opacity: 1;
  animation: surface 4s ease forwards;
}

.glass .surface::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 15%;
  margin-top: -5%;
  border-radius: 50%;
  background: #000;
  opacity: 0.35;
}

.glass .surface::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 15%;
  margin-top: -7.5%;
  border-radius: 50%;
  background: #d39909;
}

@keyframes fill {
  0% {
    height: 0%;
  }
  100% {
    height: 89%;
  }
}

@keyframes surface {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* BUBBLES */
.glass .bubbles {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.glass .bubbles .layer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;
  padding-bottom: 200%;
  background-size: 100% auto;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1526092/bubbles.png");
  background-repeat: repeat-y;
  -webkit-mask-image: linear-gradient(to top, transparent 20%, black 100%);
  animation: bubbly 3s linear infinite;
}

.glass .bubbles .layer.more {
  transform: rotate(180deg);
  -webkit-mask-image: linear-gradient(to bottom, transparent 40%, black 100%);
  animation-direction: reverse;
  animation-duration: 3.5s;
  opacity: 0.65;
}

@keyframes bubbly {
  from {
    background-position-y: 100%;
  }
}

/* HEAD */
.glass .head {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 100%;
  padding-bottom: 0%;
  opacity: 0;
  animation: fill-head 4s ease forwards;
  background: linear-gradient(
    to right,
    rgba(178, 178, 178, 1) 0%,
    rgba(235, 235, 235, 1) 50%,
    rgba(178, 178, 178, 1) 100%
  );
}

.glass .head::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 15%;
  border-radius: 50%;
  margin-bottom: -7.5%;
  background: linear-gradient(
    to right,
    rgba(178, 178, 178, 1) 0%,
    rgba(235, 235, 235, 1) 50%,
    rgba(178, 178, 178, 1) 100%
  );
}

.glass .head::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-bottom: 15%;
  margin-top: -7.5%;
  border-radius: 50%;
  background: #f4f4f4;
}

@keyframes fill-head {
  0% {
    padding-bottom: 0%;
    opacity: 0;
  }
  35% {
    padding-bottom: 0%;
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    padding-bottom: 20.5%;
  }
}

/* POUR */

.pour {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 0;
  width: 10%;
  opacity: 1;
  height: 200%;
  transform: translateX(-50%);
  animation: pour-position 4s ease forwards;
}

.pour .stream {
  content: "";
  position: absolute;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  background: linear-gradient(
    to bottom,
    rgba(211, 153, 9, 0) 0%,
    rgba(211, 153, 9, 1) 20%,
    rgba(211, 153, 9, 1) 100%
  );
  animation: pour-opacity 4s ease forwards;
}

.pour .stream::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 25%;
  border-radius: 50%;
  background: #d39909;
  transform: translateY(50%);
}

@keyframes pour-position {
  0% {
    bottom: 0;
    margin-bottom: 0%;
    height: 200%;
  }
  35% {
    margin-bottom: 0%;
  }
  100% {
    bottom: 89%;
    margin-bottom: 20.5%;
    height: 50%;
  }
}

@keyframes pour-opacity {
  0%,
  96% {
    opacity: 1;
    width: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}
